import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"

const AboutUs = ({location, data}) => {

  return (
    <Layout pageTitle="About Us" pathName={location.pathname}>
      <SEO title="About Us" />
      <Content>
        {/*
          alt intentionally left blank
          image not meant to be annoucned
        */}
        <Img fluid={data.aboutImage.childImageSharp.fluid} alt="" />
        <p>The National Commercial Vehicle and Cargo Theft Prevention Task Force (NCTTF) is comprised of members of private industry, insurance and law enforcement. The NCTTF is chaired by the National Insurance Crime Bureau.</p>
        <h2>How was the task force formed?</h2>
        <p>On Nov. 29, 2006, a meeting was held in Orlando, Fla., to discuss ongoing cargo theft initiatives, and to solicit ideas and proposals with the goal of producing a template for replication that would have a national impact on cargo theft. The overwhelming nationwide participation provided an opportunity to define and adopt a National Commercial Vehicle and Cargo Theft Prevention Strategy (adopted: Feb. 17, 2005, and re-adopted: Oct. 3, 2019). This strategy includes developing cargo theft task forces nationally, funding, threat assessment, intelligence databases, and the sharing of information. In addition to the adoption of the national strategy, a National Commercial Vehicle and Cargo Theft Prevention Task Force was formed, which is comprised of law enforcement, insurance carriers and transportation companies from across the country.</p>
      </Content>
    </Layout>
  )
}

export default AboutUs

export const query = graphql`
  query {
    aboutImage: file(relativePath: { eq: "about-2x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
